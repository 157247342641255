@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');

body {
  font-family: 'Prompt', sans-serif;
}
.css-ty7lqz-MuiPaper-root-MuiPopover-paper {
  background-color: white !important;
}
.css-bjn6kt-MuiPaper-root-MuiDrawer-paper {
  overflow-y: unset !important;
  width: 50% !important ;
  left: 25% !important;
  height: 600px !important;
}
.css-tdxonj-MuiTableHead-root {
  display: contents !important;
}
.css-4vtayc-MuiButtonBase-root-MuiButton-root.SaveButton-button {
  display: none;
}
.css-nupfgw-MuiGrid-root.Layout-basic {
  width: 100px !important;
  background-color: transparent !important;
}
.css-jrrpff .Layout-stickyContainer {
  display: none !important;
}
.shadedCell {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #9b6467;
  pointer-events: none;
}
.disableCell {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 14px,
    transparent 9px
  );
  pointer-events: none;
}
.shadedPauseCell {
  /* background: rgba(9, 231, 139, 0.1); */
  background-image: repeating-linear-gradient(
    135deg,
    rgba(15, 105, 231, 0.1),
    rgba(15, 105, 231, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #0f69e7;
  pointer-events: none;
}

/* Localhost */

.css-qh1700-MuiListItemText-root span {
  font-weight: 400px !important;
  font-size: 17px !important;
  line-height: 100px !important;
}
.css-lvl4a5-MuiDrawer-docked .MuiDrawer-paper {
  background-image: linear-gradient(0deg, rgba(0, 14, 69, 0.7), rgba(0, 14, 69, 0.7)),
    url('https://firebasestorage.googleapis.com/v0/b/shipzzer.appspot.com/o/shipzzerSideBar.jpg?alt=media&token=8ee82e40-5ec0-4f91-a92d-e32bff5cb4c6') !important;

  background-position: center center !important;
  background-size: cover !important;
  box-shadow: inset 0 0 0 2000px rgba(0, 14, 69, 0.3) !important;
}
.css-lvl4a5-MuiDrawer-docked .MuiDrawer-paper {
  width: 260px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  height: -webkit-fill-available !important;
}
.css-mvs481:hover,
.css-mvs481:focus {
  background-color: #0f69e7 !important;
}
.css-mvs481 {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;
}
.css-1siuh5p span {
  font-weight: 400px !important;
  font-size: 17px !important;
  line-height: 100px !important;
}

/* Production */

.css-1siuh5p span {
  font-weight: 400px !important;
  font-size: 17px !important;
  line-height: 100px !important;
}
.css-achccn .MuiDrawer-paper {
  background-image: linear-gradient(0deg, rgba(0, 14, 69, 0.7), rgba(0, 14, 69, 0.7)),
    url('https://firebasestorage.googleapis.com/v0/b/shipzzer.appspot.com/o/shipzzerSideBar.jpg?alt=media&token=8ee82e40-5ec0-4f91-a92d-e32bff5cb4c6') !important;

  background-position: center center !important;
  background-size: cover !important;
  box-shadow: inset 0 0 0 2000px rgba(0, 14, 69, 0.3) !important;
}
.css-achccn .MuiDrawer-paper {
  width: 260px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  height: -webkit-fill-available !important;
}
.css-mvs481:hover,
.css-mvs481:focus {
  background-color: #0f69e7 !important;
}
.css-mvs481 {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1okeesj {
  background-color: white;
}
.MuiTableHead-root.css-nnc4si {
  display: contents !important;
}

/* .MuiChip-root{
  margin-right: 0px;
float: right;
  align-items: center !important;
  justify-content: center !important;
  width: 40px!important;
  margin: auto!important;
  height: 40px!important;
  z-index: 20!important;
} */

.deleteButton:hover {
  color: #f44336 !important;
}
